import ReactDOMServer from 'react-dom/server';

let first_text_form = `
Bachelor of Science in Computer Science, Minor in Mathematics at Georgia Tech (2022-2025) /n
- Threads: Artificial Intelligence & Modeling/Simulation /n
- Faculty Honors x3 /n`

let second_text_form = `
Software Development Intern, Building Maintenance Optimization Consultants, Inc. (BMOC) /n
- Rebuilt core internal tool for building asset data collection, migrating tool's languages and platform for efficiency cost. 
/n
Research @ Georgia Tech/n
- Machine Learning Researcher, Computer Graphics Lab /n
- Software Engineering Researcher, Smart City Infrastructure Lab
`
let third_text = `
Georgia Tech Smart City Infrastructure Lab —`;

let fourth_text =  `
printed "Hello World" for the first time`;

let fifth_text = `
Apple, Vision Products Group —`;




const first_text = first_text_form.split('/n').filter(line => line.trim());
const second_text = second_text_form.split('/n').filter(line => line.trim());

export const texts2 ={
    first_text,
    second_text,
    third_text,
    fourth_text,
    fifth_text
};