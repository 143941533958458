let first_text = "Threads: Artificial Intelligence & Modeling/Simulation";
let second_text = 
    `I am interested in developing Computer Vision and Human-centered Applications.

    I wish to create opportunities that improve accessibility in the technological field.
    `;

let third_text = "Outside of that, I enjoy traveling, photography, and fashion! My contacts are linked below, and hope you enjoy my page!"
export const texts ={
    first_text,
    second_text,
    third_text
};