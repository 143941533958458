import * as React from 'react'
import { texts } from '../texts/homePageText';
import EarthCanvas from '../canvas/Earth';

const introTextJSX = (
    <p>
        My name is Wonho, and I am a student currently studying computer science @ Georgia Tech, with academic threads in <strong>AI & modeling/simulation</strong>! I am located in <strong>Atlanta, GA</strong>, but I am originally from <strong>South Korea</strong> and have lived in 4 countries. 
        As an immigrant myself, I am working to improve accessibility in the technological field. <br /><br />
        I am specifically interested in developing <strong>Computer Vision</strong> and <strong>Human-centered Applications</strong>, integrating my knowledge with applications that connect with people. I am equipped with experiences and skillsets across full-stack development and academic research.
        
    </p>
);

const About = () => {
    return (
        <div className="container py-8 mx-auto px-20 transition-all duration-300 ease-in-out">
            <div className="text-3xl font-Mukta font-bold mb-12 ">About me!</div>
            <div className="flex flex-col md:flex-row md:flex-nowrap items-center justify-center gap-8">
                {/* Gallery Column */}
                <div className="flex flex-col justify-center md:justify-start w-1/2">
                    <div className="grid gap-4">
                        <div>
                            <img className="h-auto max-w-full rounded-lg overflow-hidden transition duration-300 ease-in-out hover:-translate-y-2" src="./assets/IMG_0213.JPG" alt="" />
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <img className="h-auto max-w-full rounded-lg overflow-hidden transition duration-300 ease-in-out hover:-translate-y-2" src="./assets/museum.JPG" alt="" />
                            </div>
                            <div>
                                <img className="h-auto max-w-full rounded-lg overflow-hidden transition duration-300 ease-in-out hover:-translate-y-2" src="./assets/P6131133.jpg" alt="" />
                            </div>
                            <div>
                                <img className="h-auto max-w-full rounded-lg overflow-hidden transition duration-300 ease-in-out hover:-translate-y-2" src="./assets/IMG_9446.JPG" alt="" />
                            </div>
                            
                            
                        </div>
                    </div>
                </div>

                {/* Text Column */}
                <div className="flex flex-col lg:w-1/2 text-left text-lg ml-10 pt-10 pb-12">
                    <p className='font-Mukta'>{introTextJSX}</p>
                    <p className='font-Mukta mt-4'>{texts.third_text}</p>
                    {/* Additional paragraphs or content */}

                    <div className="mt-8 flex justify-center">
                        <div className='flex space-x-10'>
                            <a href='https://www.linkedin.com/in/wonhochoi1'
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="font-Mukta text-lg py-2 px-4 text-white bg-gradient-to-r from-green-400 to-emerald-700 rounded-full  shadow-emerald-500/50 shadow-lg hover:-translate-y-2 transition-transform duration-300 ease-in-out">
                                Contact Me!
                            </a>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default About;