import * as React from 'react'
import { motion } from 'framer-motion'
import EarthCanvas from '../canvas/Earth'

const logoStyle = {
    width: '100px', // Set a fixed width for logos
    height: '100px', // Set a fixed height for logos
    objectFit: 'contain', // Keep logo aspect ratio without cropping
    borderRadius: '0.5rem',
};

const Skills = () => {
    return (
                <div className="p-16" style={{ background: 'linear-gradient(to bottom, #1e3a8a, #172554)' }}>
                    <div className="flex justify-center items-stretch space-x-12">
                        {/* Column 1 */}
                        <div className="flex-grow shadow-strong-outline-glow bg-gray-200 bg-opacity-30 p-6 rounded-lg">
                            <h3 className="text-2xl text-white font-bold text-left mb-4">languages.</h3>
                            <div className="grid grid-cols-3 gap-8">
                                {/* Repeat for each logo */}
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/JavaLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Java</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/PythonLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Python</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/JavaScriptLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>JavaScript</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/TypeScriptLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>TypeScript</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/CLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>C</p>
                                </div>                   
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/C++Logo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>C++</p>
                                </div>         
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/SwiftLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Swift</p>
                                </div>                      
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/LanguageLogo/SQLLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>SQL</p>
                                </div>                                              
                            </div>
                        </div>

                        {/* Arrow or connecting element */}
                        <div className="items-center">
                        {/* You can use an SVG or an icon here */}
                            <svg className="h-6 w-6 text-gray-600" /* SVG attributes */>
                            {/* SVG path */}
                            </svg>
                        </div>

                        {/* Column 2 */}
                        <div className="bg-gray-200 bg-opacity-30 shadow-strong-outline-glow p-6 rounded-lg">
                            <h3 className="text-2xl text-white font-bold text-left mb-4">frameworks.</h3>
                            <div className="grid grid-cols-3 gap-4">
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/ReactLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg " />
                                    </div>
                                    <p className='text-white'>React.js</p>
                                </div>   
                                
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/AngularLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Angular.js</p>
                                </div>   
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/NodeLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Node.js</p>
                                </div>   
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/NextLogo.svg" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Next.js</p>
                                </div>                   
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/FlaskLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Flask</p>
                                </div>                    
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/NumPyLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>NumPy</p>
                                </div>                     
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/PyTorchLogo.jpeg" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>PyTorch</p>
                                </div>        
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/FrameworkLogo/TensorFlowLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>TensorFlow</p>
                                </div>                                                     
                            </div>
                        </div>

                        {/* Arrow or connecting element */}
                        <div className="items-center">
                        {/* You can use an SVG or an icon here */}
                            <svg className="h-6 w-6 text-gray-600" /* SVG attributes */>
                                {/* SVG path */}
                            </svg>
                        </div>

                        {/* Column 3 */}
                        <div className="bg-gray-200 bg-opacity-30 shadow-strong-outline-glow p-6 rounded-lg">
                            <h3 className="text-2xl text-white font-bold text-left mb-4">tools.</h3>
                            <div className="grid grid-cols-3 gap-4">
                                {/* Repeat for each logo */}
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/AWSLogo.jpeg" alt="Java" className="object-scale-down mx-auto rounded-lg"/>
                                    </div>
                                    <p className='text-white text-center'>Amazon Web Services</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/DockerLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Docker</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/FirebaseLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Firebase</p>
                                </div>  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/PostmanLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Postman</p>
                                </div>
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/JupyterLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Jupyter</p>
                                </div>                   
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/GitLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>Git</p>
                                </div>                 
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/GoogleCloudLogo.webp" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white text-center'>Google Cloud</p>
                                </div>                  
                                <div className='flex flex-col items-center space-y-2 h-full'>
                                    <div className="flex-grow flex items-center justify-center">
                                        <img src="./assets/ToolsLogo/MongoDBLogo.png" alt="Java" className="object-scale-down mx-auto rounded-lg" />
                                    </div>
                                    <p className='text-white'>MongoDB</p>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
    );
};

export default Skills;