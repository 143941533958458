import * as React from 'react';
import {Link} from 'react-router-dom';
import EarthCanvas from '../canvas/Earth';

function Header () {
    const [expandHeader, setExpandHeader] = React.useState(true);
    const collapseTimeout = React.useRef(null);


    const toggleOption = () => {
        setExpandHeader(!expandHeader);
    };
    
    const handleMouseEnter = () => {
        if (collapseTimeout.current) {
            clearTimeout(collapseTimeout.current);
            collapseTimeout.current = null;
        }
        setExpandHeader(true);
    };

    const handleMouseLeave = () => {
        collapseTimeout.current = setTimeout(() => {
            setExpandHeader(false);
        }, 500); // Delay of 500 milliseconds
    };

    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const headerClasses = expandHeader ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full';
    const pulsateClass = !expandHeader ? 'animate-pulsate' : '';

    return (
        <div className='flex flex-row ml-10 mt-4 justify-start items-center md:space-y-0'>
            <div className='flex items-center cursor-pointer'>
                <div className={`h-10 w-10 `}>
                    <EarthCanvas />
                </div>

                <div className={`flex items-center pl-6 gap-4 transition-all duration-300 ${headerClasses}`}>
                    <button onClick={() => handleScrollToSection('home')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">home</button>
                    <button onClick={() => handleScrollToSection('about')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">about</button>
                    <button onClick={() => handleScrollToSection('experience')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">experience</button>
                    <button onClick={() => handleScrollToSection('projects')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">projects</button>    
                    <button onClick={() => handleScrollToSection('skills')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">skills</button>    
                    <a href='https://drive.google.com/file/d/1QlrhT-aQR69BKzsNj4vW7HgiHPr6fvve/view?usp=sharing'
                        target="_blank" 
                        rel="noopener noreferrer"  className='text-lg font-inter text-black hover:-translate-y-1 transition-transform duration-300 ease-in-out'>resume!</a>
                    {/* Add more links here */}   
                

                    {/* Add more links here */}
                </div>
            </div>
        </div>
    )
}
export default Header
